.products-table-container {
  margin-top: 1vh;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.products-table {
  color: #7e3e1b !important;
  font-weight: 600;
  font-size: .95vw;
  border-collapse: separate;
  margin: auto;
  border-collapse: collapse;
}

.filterBar {
  color: #7e3e1b !important;
  font-weight: 600;
  width: 93vw;
  font-size: .95vw;
  margin: auto;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.products-table th {
  border: 3px solid rgba(126, 62, 27, 0.4);
  background-color: rgb(247, 246, 246);
  color: #7e3e1b !important;
  font-weight: 700;
  text-align: center !important;
  vertical-align: top;
  position: sticky;
  top: 0;
  z-index: 999;
  cursor: default;
  /* height: 7vh; */
}

.products-table tbody tr {
  /* border-bottom: 3px solid rgba(126, 62, 27, 0.2); */
  padding-bottom: 3vh;
}

.products-table tbody tr:hover {
  background-color: rgba(126, 62, 27, 0.03);
}

.data-row {
  /* border-color: #7e3e1b; */
  /* border-width: 5px !important; */
  border: 2px solid rgba(126, 62, 27, 0.2);
  vertical-align: middle;
  font-weight: 400;
  text-align: center !important;
}

.products-table td img:hover {
  transform: scale(2.5);
  box-shadow: 1px 1px 15px 1px gray;
  background-color: white;
  cursor: pointer;
  z-index: 99999;
}

/* .products-table th:nth-child(1) {
  text-align: left !important;
} */

.result:hover {
  transform: scale(2.5);
  box-shadow: 1px 1px 15px 1px gray;
  background-color: white;
  z-index: 9999999 !important;
}

.products-table td:nth-child(1) {
  text-align: left !important;
}

.hoverImageTable:hover {
  z-index: 9 !important;
}

.sourceImage:hover .overlay {
  opacity: 0;
}

.sourceImage {
  --warvis: 0;
}

.sourceImage:hover {
  --warvis: 1 !important;
}

.sortColumn {
  cursor: pointer !important;
  text-decoration: underline dotted;
  text-underline-offset: 4px;
}

.graphicon:not(.graphiconhover):hover {
  transform: scale(1) !important;
  box-shadow: 0px 0px 0px 0px rgb(160, 160, 160) !important;
}

.ai-text {
  font-family: 'Orbitron', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #005058;
  text-shadow: 
      0 0 5px #410000, 
      0 0 15px #820000, 
      0 0 30px #3b0000, 
      0 0 50px #780000;
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% { text-shadow: 0 0 10px #ff0000, 0 0 30px #ff1e00, 0 0 50px #ff2600; }
  100% { text-shadow: 0 0 20px #ff8800, 0 0 40px #ffc800, 0 0 70px #ff9900; }
}